export default [
	{
		header: 'Projekte',
	},
	{
		title: 'Anfragen',
		icon: 'MessageCircleIcon',
		route: 'wercbrain-requests',
	},
	{
		title: 'Kunden',
		icon: 'UsersIcon',
		route: 'wercbrain-customers',
	},
	// {
	// 	title: 'Projekt-Liste',
	// 	icon: 'ShoppingCartIcon',
	// 	route: 'wercbrain-projects',
	// },
	{
		header: 'Produktion',
	},
	// {
	// 	title: 'Produktionsplan',
	// 	icon: 'MapIcon',
	// 	route: 'wercbrain-production',
	// },
	// {
	// 	title: 'Ext. Bestellungen',
	// 	icon: 'TruckIcon',
	// 	route: 'wercbrain-external-orders',
	// },
	{
		title: 'Produkte',
		icon: 'PackageIcon',
		route: { name: 'wercbrain-products', params: { id: 4987 } },
	},
	// {
	// 	title: 'Kunden',
	// 	icon: 'UserCheckIcon',
	// 	route: 'wercbrain-customers',
	// },
	// {
	// 	title: 'Ressourcen',
	// 	icon: 'ArchiveIcon',
	// 	route: 'wercbrain-resources',
	// },
	// {
	// 	header: 'Intern',
	// },
	{
		title: 'Mitarbeiter',
		icon: 'UserIcon',
		route: 'wercbrain-employees',
	},
	{
		title: 'Dateien',
		icon: 'FileIcon',
		route: 'wercbrain-file-explorer',
	},
	// {
	// 	title: 'Kalender',
	// 	icon: 'CalendarIcon',
	// 	route: 'wercbrain-calendar',
	// },
	{
		header: 'Einstellungen',
	},
	// {
	// 	title: 'Allgemein',
	// 	icon: 'SettingsIcon',
	// 	route: 'wercbrain-settings-general',
	// },
	{
		title: 'Wörterbuch',
		icon: 'BookIcon',
		route: 'wercbrain-settings-dictionary',
	},
	{
		title: 'Angebots-Variablen',
		icon: 'SlidersIcon',
		route: 'wercbrain-settings-calc-variables',
	},

	// {
	// 	title: 'Settings',
	// 	icon: 'SettingsIcon',
	// 	children: [
	// 		{
	// 			title: 'Allgemein',
	// 			route: 'wercbrain-settings-dictionary',
	// 		},
	// 		{
	// 			title: 'Wörterbuch',
	// 			route: 'wercbrain-settings-dictionary',
	// 		},
	// 		{
	// 			title: 'Produkte',
	// 			route: { name: 'wercbrain-settings-products', params: { id: 4987 } },
	// 		},
	// 	],
	// },
]
